// src/components/Home.js
import React from 'react';
import { useSpring, useTrail, animated } from '@react-spring/web'; // Sử dụng thêm useTrail
import './Home.css';

const Home = () => {
    // Tạo animation fade-in cho toàn bộ container
    const fadeIn = useSpring({
        from: { opacity: 0, transform: 'translateY(30px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { tension: 200, friction: 20, duration: 500 },
    });

    // Tạo hiệu ứng trail cho các phần tử con (h1 và p)
    const trail = useTrail(4, {
        from: { opacity: 0, transform: 'translateX(-20px)' },
        to: { opacity: 1, transform: 'translateX(0)' },
        config: { mass: 1, tension: 200, friction: 20 },
    });

    return (
        <animated.div style={fadeIn} className="home-container">
            <animated.h1 style={trail[0]}>Chào mừng đến với CORS Helper!</animated.h1>
            <animated.p style={trail[1]}>
                Ứng dụng này giúp bạn vượt qua lỗi CORS (Cross-Origin Resource Sharing) bằng cách sử dụng một proxy server trung gian.
            </animated.p>
            <animated.h2 style={trail[2]}>CORS là gì?</animated.h2>
            <animated.p style={trail[3]}>
                CORS (Cross-Origin Resource Sharing) là một cơ chế bảo mật của trình duyệt, được thiết kế để ngăn chặn các trang web từ một nguồn khác nhau (origin) truy cập vào tài nguyên của một trang web khác mà không được phép.
            </animated.p>
            <animated.h2 style={trail[0]}>Tại sao lại xảy ra lỗi CORS?</animated.h2>
            <animated.p style={trail[1]}>
                Lỗi CORS xảy ra khi bạn cố gắng truy cập tài nguyên từ một nguồn khác mà không có quyền truy cập từ server của tài nguyên đó. Điều này nhằm bảo vệ người dùng khỏi các cuộc tấn công như Cross-Site Scripting (XSS) và Cross-Site Request Forgery (CSRF).
            </animated.p>
            <animated.h2 style={trail[2]}>Cách sử dụng Proxy Server để vượt qua CORS</animated.h2>
            <animated.ol style={trail[3]}>
                <li>Sử dụng proxy server của chúng tôi: <code>https://cors.hideon.dev/proxy?url=YOUR_API_URL</code></li>
                <li>Thay thế <code>YOUR_API_URL</code> bằng URL của API mà bạn muốn truy cập.</li>
                <li>Proxy server này sẽ gửi yêu cầu thay mặt bạn và trả về kết quả mà không bị hạn chế CORS.</li>
                <li>Tích hợp URL proxy này vào ứng dụng của bạn để gọi API mà không gặp lỗi CORS.</li>
            </animated.ol>
            <animated.h2 style={trail[0]}>Ví dụ sử dụng Proxy Server</animated.h2>
            <animated.p style={trail[1]}>
                Nếu bạn muốn gọi một API từ dịch vụ khác, ví dụ: <code>https://api.example.com/data</code>, bạn chỉ cần thay thế như sau:
            </animated.p>
            <animated.code style={trail[2]}>
                https://cors.hideon.dev/proxy?url=https://api.example.com/data
            </animated.code>
            <animated.p style={trail[3]}>
                Bây giờ bạn có thể sử dụng URL proxy này trong ứng dụng của mình để gọi API mà không gặp lỗi CORS.
            </animated.p>
            <animated.h2 style={trail[0]}>Lưu ý</animated.h2>
            <animated.p style={trail[1]}>
                Proxy server này chỉ nên được sử dụng cho mục đích học tập và phát triển. Không nên sử dụng cho các ứng dụng sản xuất mà đòi hỏi độ bảo mật cao.
            </animated.p>
        </animated.div>
    );
};

export default Home;
